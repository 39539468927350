import { ArrowRightIcon } from "@heroicons/react/solid"
import { navigate } from "gatsby"
import React from "react"
import Button from "../components/common/Button"
import Layout from "../components/layout/Layout"
import { LANDING_ROUTE } from "../constants/routes"
import notFoundImage from "../images/not-found-image.svg"

export default () => {
  return (
    <Layout>
      <div className="h-screen w-full md:1/2 m-auto flex flex-col items-center justify-center gradient-brand pb-16">
        <img className="p-4" src={notFoundImage} alt="Page not found" />
        <Button
          className=" py-4 px-6 mt-10"
          onClick={() => navigate(LANDING_ROUTE)}
        >
          {" "}
          <div className="inline-block">
            <span className="inline-block">Go back to Geelnet </span>
            <ArrowRightIcon className="ml-4 h-5 w-5 inline" />
          </div>
        </Button>
      </div>
    </Layout>
  )
}
